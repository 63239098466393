// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-recipe-js": () => import("/opt/build/repo/src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-meta-archive-js": () => import("/opt/build/repo/src/templates/meta-archive.js" /* webpackChunkName: "component---src-templates-meta-archive-js" */),
  "component---src-templates-meal-js": () => import("/opt/build/repo/src/templates/meal.js" /* webpackChunkName: "component---src-templates-meal-js" */),
  "component---src-templates-cuisine-js": () => import("/opt/build/repo/src/templates/cuisine.js" /* webpackChunkName: "component---src-templates-cuisine-js" */),
  "component---src-templates-difficulty-js": () => import("/opt/build/repo/src/templates/difficulty.js" /* webpackChunkName: "component---src-templates-difficulty-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

